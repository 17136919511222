const SakuData = [
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7369454180014001429?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7370177399989767441?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7372216915504237832?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7371364924389362952?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7372229754969214216?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7373882918910872839?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7373893080212098311?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7373896118872165640?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7354272757653310725?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7354448239862631700?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7354442444383079696?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7353471906877623569?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7351292817823550728?is_from_webapp=1&sender_device=pc"
  },
  {
    link: "https://www.tiktok.com/@saku.laundry/video/7350528186192530689?is_from_webapp=1&sender_device=pc"
  },
  ];
  
export default SakuData;
  