import apple from "../../assets/img/apple.png";
import bca from "../../assets/img/Logo-BCA-PNG.png";
import stem from "../../assets/img/stem.png";
import microsoft from "../../assets/img/microsoft.png";
import atv from "../../assets/img/ATV.png";

const interns = [
    {
        location: "Kota Tangerang Selatan, Indonesia",
        date: "February 2024 – Present",
        title: "ATV Creative Asia",
        jobdesc: "Video Editor",
        iconSrc: atv,
        iconAlt: "Apple Icon",
        iconWidth: "30px"
    },
    {
        location: "Tangerang, Indonesia",
        date: "March 2023 – December 2023",
        title: "Apple Developer Academy @BINUS Cohort 6",
        jobdesc: "iOS Developer Learner",
        iconSrc: apple,
        iconAlt: "Apple Icon",
        iconWidth: "30px"
    },
    {
        location: "Jakarta, Indonesia",
        date: "August 2022 – December 2022",
        title: "PT Bank Central Asia",
        jobdesc: "IT Specialist Intership Program",
        iconSrc: bca,
        iconAlt: "bca Icon",
        iconWidth: "50px"
    },
    {
        location: "Tangerang, Indonesia",
        date: "February 2021 – September 2022",
        title: "STEM PRASETIYA MULYA",
        jobdesc: "Creative Digital Team - Part Time",
        iconSrc: stem,
        iconAlt: "stem Icon",
        iconWidth: "50px"
    },
    {
        location: "Jakarta, Indonesia",
        date: "August 2021 – December 2021",
        title: "Microsoft Indonesia",
        jobdesc: "Studi Independent - Kampus Merdeka",
        iconSrc: microsoft,
        iconAlt: "microsoft Icon",
        iconWidth: "50px"
    },
];

export default interns;
